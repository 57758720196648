/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-27 20:10:44
 * @Module: 主播详情
 */
 <template>
  <div>
    <transition name="el-zoom-in-top">
      <el-card class="anchor-detail"
               v-show="showDetail"
               v-loading="loading">
        <div class="anchor-detail-top"
             v-role="['administrator']">
          <div style="font-size:18px;">主播信息</div>
          <div style="color:#3278EB;cursor: pointer;"
               @click="editClick">
            <i class="el-icon-edit-outline"></i>
            {{role('administrator')?'修改签约信息':'修改备注'}}
          </div>
        </div>
        <div class="anchor-detail-info"
             v-if="anchorDetail"
             v-role="['administrator']">
          <div style="width:385px;height:114px;"
               class="flex justify-between">
            <el-avatar :size="94"
                       :src="anchorDetail.avatar"></el-avatar>
            <div style="width:271px;height:114px;padding-top:12px;padding-bottom:8px;"
                 class="flex flex-direction justify-between">
              <div class="text-xl">{{anchorDetail.nick_name}}</div>
              <div class="flex text-sm justify-between">
                <div class="text-cut">陌陌ID：{{anchorDetail.tick_account}}</div>
                <!-- <div class="text-cut">陌陌id：{{anchorDetail.tick_id}}</div> -->
              </div>
              <div class="text-sm">
                <div class="text-cut">签约时间：{{anchorDetail.contract_time}}</div>
              </div>
              <div class="text-sm">
                <div class="text-cut">主播来源：{{anchorDetail.origin==0?'自招主播':anchorDetail.origin==1?'星探推荐':'--'}}</div>
              </div>
            </div>
          </div>
          <div style="width:216px;height:90px;"
               class="flex flex-direction justify-between">
            <div class="anchor-detail-descriptions text-sm text-cut">
              <div>姓名：</div>
              <div>{{anchorDetail.name}}</div>
            </div>
            <div class="anchor-detail-descriptions text-sm text-cut">
              <div>手机号：</div>
              <div>{{anchorDetail.phone}}</div>
            </div>
            <div class="anchor-detail-descriptions text-sm text-cut">
              <div>备注：</div>
              <div>{{anchorDetail.description||'--'}}</div>
            </div>
            <div class="anchor-detail-descriptions text-sm text-cut">
              <div>星探名字：</div>
              <div>{{anchorDetail.star_user_name}}</div>
            </div>
          </div>
          <div style="width:216px;height:90px;margin-right:143px;"
               class="flex flex-direction justify-between">
            <div class="anchor-detail-descriptions text-sm text-cut">
              <div>所属公会：</div>
              <div>{{anchorDetail.mcn}}</div>
            </div>
            <div class="anchor-detail-descriptions text-sm text-cut">
              <div>经纪人：</div>
              <div>{{anchorDetail.broker_name}}</div>
            </div>
            <div class="anchor-detail-descriptions text-sm text-cut">
              <div>分成比：</div>
              <div>{{anchorDetail.separate_ratio}}%</div>
            </div>
            <div class="anchor-detail-descriptions text-sm text-cut">
              <div>王牌运营：</div>
              <div>{{anchorDetail.star_broker_name}}</div>
            </div>
          </div>
        </div>
        <div class="flex justify-between align-center"
             style="height:58px;">
          <div style="font-size:18px;">直播数据</div>
          <el-date-picker size="mini"
                          v-model="broadcast_date"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"
                          @change="pickerChange"></el-date-picker>
        </div>
        <el-table :data="anchorDetailData.records"
                  style="width: 100%">
          <el-table-column prop="date"
                           label="开播时间"></el-table-column>
          <el-table-column prop="duration"
                           label="直播时长(分钟)"></el-table-column>
          <el-table-column prop="total"
                           label="总直播礼物收入"></el-table-column>
          <!-- <el-table-column prop="effective_day"
                           label="开播有效天"></el-table-column> -->
          <el-table-column prop="fans_num"
                           label="新增粉丝数"></el-table-column>
        </el-table>
      </el-card>
    </transition>
    <router-view></router-view>
  </div>
</template>
 <script>
export default {
  components: {
  },
  data () {
    return {
      anchor_id: null,
      anchorDetail: null,
      anchorDetailData: {
        charts: {
          total: {
            data: [],
            dates: [],
            lines: [],
          },
        },
        records: [],
      },
      datePickerDefaultValue: [
        this.$timeFormat(
          new Date().setTime(
            new Date(new Date().getFullYear(), new Date().getMonth(), 1)
          ),
          "yyyy-mm-dd"
        ),
        this.$timeFormat(new Date().getTime(), "yyyy-mm-dd"),
      ],
      broadcast_date: null,
      loading: true,
    };
  },
  created () {
    this.$store.commit("layouts/setPathList", [
      { name: "主播列表", path: "/anchor/anchorList" },
      { name: "主播详情" },
    ]);
    this.$store.commit("layouts/changeBreadcrumb", true);
    const { anchor_id } = this.$router.history.current.query;
    if (anchor_id) this.$setStorage("anchor_detail_id", anchor_id);
    this.anchor_id = anchor_id || this.$getStorage("anchor_detail_id");
    this._getAnchorDetail();
    this.reqAnchorDetailData();
  },
  beforeDestroy () {
    this.$store.commit("layouts/changeBreadcrumb", false);
  },
  mounted () { },
  methods: {
    // 编辑主播
    editClick () {
      this.$router.push({
        name: "anchorEdit",
        params: this.anchorDetail,
      });
    },
    // 请求顶部主播数据
    async _getAnchorDetail (params = { anchor_id: this.anchor_id }) {
      const { status_code, message } = await this.$api.anchorDetail(params);
      if (status_code == 200) this.anchorDetail = message;
    },
    //请求主播详情列表和图表数据
    async _getAnchorDetailData (
      params = {
        anchor_id: this.anchor_id,
        broadcast_date: this.broadcast_date,
      }
    ) {
      const { status_code, message } = await this.$api.anchorDetailData(params);
      this.loading = false;
      if (status_code == 200) {
        this.anchorDetailData = message;
      } else if (status_code == 422) {
        this.$message.error(message.broadcast_date);
      }
    },
    reqAnchorDetailData () {
      if (!this.broadcast_date)
        this.broadcast_date = this.datePickerDefaultValue;
      this._getAnchorDetailData();
    },
    pickerChange () {
      this.reqAnchorDetailData();
    },
  },
  computed: {
    showDetail () {
      return this.$route.name == "anchorDetail";
    },
  },
};
</script>
 <style lang="less" scoped>
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.flex-direction {
  flex-direction: column;
}
.text-sm {
  font-size: 14px;
}
.text-xl {
  font-size: 18px;
}
.text-xxl {
  font-size: 20px;
}
.color-default {
  color: #3278eb;
}
.text-cut {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.align-center {
  align-items: center;
}
.anchor-detail-descriptions {
  display: flex;
  width: 100%;
  color: #333;
  div:first-child {
    width: 70px;
    color: #666;
  }
}
.anchor-detail {
  .anchor-detail-top {
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .anchor-detail-info {
    padding: 18px 0px;
    display: flex;
    justify-content: space-between;
  }
}
</style>